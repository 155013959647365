import Theme from '@mediashop/app/Theme';
import CatalogBaseTheme from '@mediashop/catalog-base';
import './pattern/template/Meta/index.tsx';

const TelsellTheme: Theme = {
    name: 'telsell',
    extends: CatalogBaseTheme,
};

export default TelsellTheme;
