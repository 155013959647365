import { Helmet } from 'react-helmet-async';
import { injectComponent } from '@mediashop/app/component-injector';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Corporation, WithContext } from 'schema-dts';
import { useProject } from '@mediashop/app/hooks/useProject';

function StructuredData() {
    const { shippingCountries } = useProject();

    const telsellSchema: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop Benelux B.V h/o TELSELL.com',
        url: 'https://www.telsell.com/',
        logo: 'https://www.telsell.com/_img/100_100/site/binaries/content/gallery/images/telsell/logo/telsell_logo_v3.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '08002400024',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: 'Dutch',
        },
        sameAs: [
            'https://nl-nl.facebook.com/TelSell/',
            'https://www.instagram.com/telsell_official/',
            'https://www.youtube.com/c/telsell',
        ],
    };

    return <Helmet script={[helmetJsonLdProp(telsellSchema)]}></Helmet>;
}

export default injectComponent('pattern.template.Meta.StructuredData', StructuredData, 'telsell');
